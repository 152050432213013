<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('dashboards') }}</span>
        </h1>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <!-- DASHBOARDS -->
    <div class="mt-4">
      <DashboardListTable
        :reload="reload"
      />
    </div> <!-- END DASHBOARDS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import DashboardListTable from '@/components/dashboards/DashboardListTable'

export default {
  components: {
    DashboardListTable
  },
  data () {
    return {
      reload: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('dashboards')
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Handle upsert
     */
    handleUpsert (dashboard) {
      this.$router.push({ name: 'dashboards.show', params: { id: dashboard.id } })
    },
    /**
     * Reload dashboards
     */
    reloadDashboards () {
      this.reload = true
      setTimeout(() => {
        this.reload = false
      }, 300)
    }
  }
}
</script>
