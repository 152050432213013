import axios from '@/plugins/axios'

/**
 * Get single dashboard
 */
const getDashboard = async id => {
  try {
    const response = await axios.get(`dashboards/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get dashboards
 */
const getDashboards = async ({
  companyId,
  page = 1,
  itemsPerPage = 9
}) => {
  try {
    const params = {
      company_id: companyId
    }
    params.page = page
    params.items_per_page = itemsPerPage
    const response = await axios.get('dashboards', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const requestDashboard = async ({
  userId,
  companyId,
  advertiserId
}) => {
  try {
    const params = {
      user_id: userId,
      company_id: companyId,
      advertiser_id: advertiserId
    }

    const response = await axios.post('dashboards/request-creation', params)

    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getDashboard,
  getDashboards,
  requestDashboard
}
